input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
@-webkit-keyframes autofill {
    0%,100% {
        color: #666;
        background: transparent;
    }
}

.dig_table_overflow{
    border: 1px solid #51b0e7;
    padding: 0 10px 0 10px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 7px;
    height: 100%;
  }

input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}


.ipcss_date,.ipcss_date:hover, .ipcss_date:active,.ipcss_date:focus{
    line-height: 16px !important;
    font-size: 14px !important;
    display: block;
    margin-top: 2px !important;
    line-height: 1.42857143;
    color: black;
    background-image: none;
    border: 1px solid #2ca8fe;
    /* box-shadow: 0 0 0.5px darkgrey; */
    border-radius: 4px;
    font-family: Arial;
    /* margin-bottom: 10px; */
    width: 100%;
    outline: none;
    background-color: #fff;
    padding: 2px 2px!important;
}

#card_tbl{
    margin: 0 auto;
    width: 100%;
}
#card_tbl tr{
    border-bottom: 1px solid #e9ecef;
    padding: 0px;
}
#card_tbl th {
    padding: 0 6px;
    height: 30px;
    background: #51b0e7;
    color: #fff;
}
#card_tbl td {
    padding: 0 6px;
    height: 30px;
    border: 1px solid #51b0e7;
}

.closeoption{
    color: #000;
    float: right
}
.ipcss,.ipcss:active,.ipcss:focus,.ipcss:hover{
    display: block !important;
    margin-top: 2px !important;
    padding: 3px 3px !important;
    font-size: 13px !important;
    line-height: 1.42857143;
    color: black;
    background-image: none;
    background-color: #fff;
    border: 1px solid #2ca8fe;
    box-shadow: 0 0 0.5px darkgrey;
    border-radius: 4px;
    font-family: Arial;
    /* margin-bottom: 10px; */
    width: 100%;
    outline: none !important;
}
.ipcss:disabled{
    color: black !important;
}
.dataLable{
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #1c1c1cba;
}
.matlabel{
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px !important;
    font-family: Arial;
    font-weight: 400;
    letter-spacing: 0.5px;
   
}
.text_area{
    height: 50px;
}

table th, table td {
    /* font-family: Arial; */
    letter-spacing: 1px;
   
}
.table th{
    height: 31px;
    padding: 0;
    color: #fff;
    background: #51b0e7;
  }
  
  .table td{
    height: 31px;
    padding: 0;
  }
  
.card{
    box-shadow: none !important;
}
/* .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: #1c1c1cba;
} */

    .ipcss:required:invalid{  
        border-color:  rgb(250, 40, 2);
    }
            
    .ipcss:required:valid{
        border-color: #2ca8fe;
    }

    .ipcss_date:required:invalid{  
        border-color:  rgb(250, 40, 2);
    }
            
    .ipcss_date:required:valid{
        border-color: #2ca8fe;
    }
      
    
.tervys_heading {
    font-weight: 600;
    font-family: Arial;
    color: black;
    font-size: 13px;
    margin: 10px 0 !important;
}
.viewrximgbtn_inpatinfo.viewrximgbtn_inpatinfo, .writerximgbtn_inpatinfo, .disimgbtn_inpatinfo, .saveimgbtn_inpatinfo, .updateimgbtn_inpatinfo, .backimgbtn_inpatinfo{
    width: 85px;
    height: auto;
}
.dataTables_wrapper .dataTables_filter {
    float: none !important;
}  
.btn.btn-sm {
    padding: 0.2vw 1vw !important;
    font-size: 0.7vw !important;
}
#so_report_tbl{
    border-collapse: collapse;
    width: 80%;
    margin:0px auto;
    text-align: center;
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
}

#so_report_tbl tr{
    border: 1px solid #ddd;
}

#so_report_tbl td, #so_report_tbl th {
    padding: 1px 2px 1px 2px;
}
#so_report_tbl tr:nth-child(even){background-color: #fff;}

#so_report_tbl tr:hover {background-color: #fff;}
#so_report_tbl th {
    font-family: Arial;
    height: 34px;
    background-color: #51b0e7 !important;
    color: #616c76 !important;
    font-size: 13px;
}
#so_report_tbl td{
    color: #5b5b5b !important;
    height: 112px;
    position: relative;
    left: 12px;
}
.nodata{
    width: 75%;
    max-width: 350px;
    /* margin-top: 50px !important; */
    padding: 20px;
    font-family: Arial;
    font-size: 13px;
    text-align: center;
    zoom: 1;
    background-repeat: no-repeat;
    background-position: 60px 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR…b0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
    background-color: #b0dbe9;
    color: black;
    border-radius: 10px;
    display: table;
    margin: 25px auto !important;
}
.editDelicon{
    width: 15px;
    height: auto;
}


::ng-deep .mat-icon-button{
    background-color:#fff;
    height: 0px !important;
    width: 0px !important;
    border-radius: none;
}

/* mdb-card with tables */
::ng-deep div.dataTables_filter {
    text-align: left !important;
    /* background-image: url(../../../assets/img/search.png); */
    
}
::ng-deep div.dataTables_filter input {
    min-width: 400px !important;
    font-size: 12px;
    font-family: Arial;
    font-weight: 600;
}

::ng-deep .dataTables_info{
    float: right !important;
    font-size: 12px !important;
    font-family: Arial;
    font-weight: 600;
}

::ng-deep .paginate_button {
    font-size: 12px !important;
    font-family: Arial;
    font-weight: 600;
}

::ng-deep .dataTables_length, ::ng-deep .pagination .page-item .page-link, ::ng-deep .dataTables_filter{
    font-size: 0.875rem !important;
}
::ng-deep .dataTables_length label {
    margin:0 !important;
}
::ng-deep .dataTables_length{
    float: right !important;
}
::ng-deep .dataTables_length .custom-select {
    height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    font-size: 0.875rem !important;
    display: inline-block !important;
    margin: 0 !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center no-repeat #fff !important;
    background-size: 8px 10px !important;
}
::ng-deep .dataTables_filter input,.dataTables_filter input:hover, .dataTables_filter input:active {
    padding-top: 0 !important;  
    padding-bottom: 0 !important;
    margin-bottom: 5px  !important;
    border-radius: none !important;
    border: none !important;
    border-bottom: 1px solid #666 !important;
    outline: none !important;
}
::ng-deep .pagination .page-link:focus {
    background-color: #007bff !important;
}

.table-responsive1{
    border: none;
}
/* tab animation */
.tab-pane, .table_cover{
    animation: slide-left 0.3s ease-out;
}

@keyframes slide-left {
    0% { opacity: 0; transform: translateX(100%); }
    100% { opacity: 1; transform: translateX(0); }
}
/* end animation */

/* // responsive style start
//mobile */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .matlabel {
        font-size: 12px;
    }
    .dataLable{
        font-size: 12px;
    }
    .viewrximgbtn_inpatinfo.viewrximgbtn_inpatinfo, .writerximgbtn_inpatinfo, .disimgbtn_inpatinfo, .saveimgbtn_inpatinfo, .updateimgbtn_inpatinfo, .backimgbtn_inpatinfo{
        width: 47px;
        height: auto;
    }
    .btn.btn-sm {
        /* padding: 0.6vw 1vw !important; */
        font-size: 1.5vw !important;
    }
    #so_report_tbl th {
        font-size: 12px;
    }
    .tervys_heading{
        font-size: 13px;
    }
}
/* //small tab */
@media only screen and (min-device-width : 481px) and (max-device-width : 639px) {
    .matlabel {
        font-size: 12px;
    }
    .dataLable{
        font-size: 12px;
    }
    .viewrximgbtn_inpatinfo.viewrximgbtn_inpatinfo, .writerximgbtn_inpatinfo, .disimgbtn_inpatinfo, .saveimgbtn_inpatinfo, .updateimgbtn_inpatinfo, .backimgbtn_inpatinfo{
        width: 60px;
        height: auto;
    }
    #so_report_tbl th {
        font-size: 12px;
    }
    .tervys_heading{
        font-size: 13px;
    }
}
/* //tab */
@media (min-width: 640px) and (max-width: 991px) {
    .matlabel {
        font-size: 12px;
    }
    .dataLable{
        font-size: 12px;
    }
    .viewrximgbtn_inpatinfo.viewrximgbtn_inpatinfo, .writerximgbtn_inpatinfo, .disimgbtn_inpatinfo, .saveimgbtn_inpatinfo, .updateimgbtn_inpatinfo, .backimgbtn_inpatinfo{
        width: 65px;
        height: auto;
    }
    #so_report_tbl th {
        font-size: 12px;
    }
    .tervys_heading{
        font-size: 13px;
    }
}
 /* //tab rotation */
@media (min-device-width: 992px) and (max-device-width: 1024px)  {
    
}
/* // large screen  */
@media (min-device-width: 1440px) and (max-device-width: 5120px)  {
    .matlabel {
        font-size: 13px;
    }
    .tervys_heading{
        font-size: 14px;
    }
}
.pad_top_10{
    padding-top: 10px;
}
.pad_top_15{
    padding-top: 10px;
}
/* new styles  start*/
.cover_div{
        border: 2px solid #66b9eb;
        position: relative;
        padding: 15px 20px 9px 20px;
        border-radius: 15px;
        margin-top: 15px;
}
.header_lable{
    position: absolute;
    background: #fff;
    padding: 0px 10px;
    top: -11px;
    left: 15px;
    font-weight: 500;
    font-size: 12px !important;
}
.align_left{
    text-align: left !important;
    padding-left: 10px;
}

.align_right{
    text-align: right !important;
    padding-right: 10px;
}
/* .app_list_header{
    background:url(../ui_icons/appointment_page_bar.svg);
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    color: #fff;
    background-color: transparent !important;
    border-bottom: none !important;
    padding: 13px 13px;
} */
.app_list_header{
    background: url(../../assets/ui_icons/appointment_page_bar.svg);
    background-color: transparent !important;
    border-bottom: none !important;
    height: 45px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.mainHeadingStyle{
    font-size: 17px !important;
    color: #fff;
    margin: 0px !important;
    /* padding: 13px 16px; */
    position: relative;
    top: 9px;
    left: 14px;   
    padding: 2px 0;
}
.headerCover{
    display: flex;
    width: 100%;
    border-top-left-radius: 12px;
}
.headerTilte{
    display: inline-block;
    width: 277px;
}
.headerButtons{
    flex-grow: 1;
    text-align: right;
    border-top: 1px solid #59b4e9;
    justify-content: right;
    display: flex;
    align-items: center;
    
}
.bg-white{
    border-bottom: none !important;
}
select{
    padding: 4px 3px !important;
}
.pluseIcon{
    height: 20px;
    width: 20px;
   
}
.flexClass{
    display: flex;
    align-items: center;
}
