/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// Font Awesome
// @import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "~@fortawesome/fontawesome-free/scss/solid.scss";
// @import "~@fortawesome/fontawesome-free/scss/regular.scss";
// @import "~@fortawesome/fontawesome-free/scss/brands.scss";

// // MDB SCSS
// @import "~mdb-angular-ui-kit/assets/scss/mdb.scss";


@font-face {
    font-family: Lato-Bold;
    src: url(assets/fonts/Lato/Lato-Bold.ttf) format("opentype");
  }
  @font-face {
      font-family: Lato-Regular;
      src: url(assets/fonts/Lato/Lato-Regular.ttf) format("opentype");
  }
  @font-face {
      font-family: Lato-Light;
      src: url(assets/fonts/Lato/Lato-Light.ttf) format("opentype");
  }
  @font-face {
      font-family: Lato-Italic;
      src: url(assets/fonts/Lato/Lato-Italic.ttf) format("opentype");
  }
  @font-face {
      font-family: Montserrat-Bold;
      src: url(assets/fonts/Montserrat/Montserrat-Bold.ttf) format("opentype");
  }
  @font-face {
      font-family: Montserrat-ExtraBold;
      src: url(assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("opentype");
  }
  @font-face {
      font-family: Montserrat-Medium;
      src: url(assets/fonts/Montserrat/Montserrat-Medium.ttf) format("opentype");
  }
  @font-face {
      font-family: Montserrat-Regular;
      src: url(assets/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype");
  }
  @font-face {
    font-family: OpenSans-Regular;
    src: url(assets/fonts/Open_Sans/OpenSans-Regular.ttf) format("opentype");
}
@font-face {
    font-family: OpenSans-Bold;
    src: url(assets/fonts/Open_Sans/OpenSans-Bold.ttf) format("opentype");
}
@font-face {
    font-family: OpenSans-SemiBold;
    src: url(assets/fonts/Open_Sans/OpenSans-SemiBold.ttf) format("opentype");
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url(assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("opentype");
}

html {
    height: 100%;
}
body{
    height: 100%;
    // overflow: hidden !important;
}
// .row{
//     width: 100% !important;
// }
// scroll bar desing
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7985d1; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #364ee1;
  } 

  /* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#slider_section .owl-nav div.owl-prev{
    font-size: 42px;
    color: #bfbbbb;
    position: absolute;
    left: 16px;
    top: 40%;
    z-index: 1;
    background: none;
}
 
#slider_section .owl-nav div.owl-next {
    font-size: 42px;
    color: #bfbbbb;
    position: absolute;
    right: 16px;
    top: 40%;
    z-index: 1;
    background: none;
}
#chooseUs_slider_cover .owl-nav div.owl-prev{
    font-size: 50px;
    color: #bfbbbb;
    position: absolute;
    left: 16px;
    top: 24%;
    z-index: 1;
    background: none;
}
#chooseUs_slider_cover .owl-nav div.owl-next{
    font-size: 50px;
    color: #bfbbbb;
    position: absolute;
    right: 16px;
    top: 24%;
    z-index: 1;
    background: none;
}
#sp_slider_cover .owl-nav div.owl-prev{
    font-size: 42px;
    color: #bfbbbb;
    position: absolute;
    left: -43px;
    top: 40%;
    z-index: 1;
    background: none;
}
#sp_slider_cover .owl-nav div.owl-next{
    font-size: 42px;
    color: #bfbbbb;
    position: absolute;
    right: -49px;
    top: 40%;
    z-index: 1;
    background: none;
}


.owl-carousel .owl-nav.disabled{
    display: block !important;
}
/*accordian section*/
.faq {width: 100%; clear:both}
.faqitem .header {padding: 10px 0 0;display: flex;justify-content: space-between;align-items: center;cursor: pointer;clear:left;border-bottom: 1px solid #dcdee4;}
.faqitem .header p {margin: 0;font-size:15px; color: #0f86ff;    font-family: Arial
    !important; width:100%;float: left; min-height:0}
.faqitem .header p:hover,.faqitem .header p:active, .faqitem .header p:focus{ outline: none;    color: #0f86ff;}
.faqitem .header .fa.fa-minus {display: none;}
.faqitem .header i{background-color: #dcdee4;    height: 25px;    width: 35px;    text-align: center;    padding-top: 7px; color:#3a3c41}
.faqitem p{margin:25px 0}
.faqitem.jquery-accordion-active .fa.fa-minus {display: block;}
.faqitem.jquery-accordion-active .fa.fa-plus {display: none;}
.faqitem .content {padding: 0;display: none; margin-top:0}
.faqitem .content p{
  font-size:15px;
}
/*accordian section*/
// responsive style start
//mobile
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #slider_section .owl-nav div.owl-next,#slider_section .owl-nav div.owl-prev {
        font-size: 4vw;
        top: 42%;
    }
    #chooseUs_slider_cover .owl-nav div.owl-next {
        font-size: 7vw;
        right: 10px;
    }
    #chooseUs_slider_cover .owl-nav div.owl-prev {
        font-size: 7vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-prev{
        font-size: 4vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-next{
        font-size: 4vw;
        right: 10px;
    }
}
//small tab
@media only screen and (min-device-width : 481px) and (max-device-width : 639px) {
    #slider_section .owl-nav div.owl-next,#slider_section .owl-nav div.owl-prev {
        font-size: 4vw;
        top: 42%;
    }
    #chooseUs_slider_cover .owl-nav div.owl-next {
        font-size: 7vw;
        right: 10px;
    }
    #chooseUs_slider_cover .owl-nav div.owl-prev {
        font-size: 7vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-prev{
        font-size: 4vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-next{
        font-size: 4vw;
        right: 10px;
    }
}
//tab
@media (min-width: 640px) and (max-width: 959px) {
    #slider_section .owl-nav div.owl-next,#slider_section .owl-nav div.owl-prev {
        font-size: 3.7vw;
        top: 42%;
    }
    #chooseUs_slider_cover .owl-nav div.owl-next {
        font-size: 5.7vw;
        right: 10px;
    }
    #chooseUs_slider_cover .owl-nav div.owl-prev {
        font-size: 5.7vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-prev{
        font-size: 3.7vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-next{
        font-size: 3.7vw;
        right: 10px;
    }
}
 //tab rotation
@media (min-device-width: 960px) and (max-device-width: 1024px)  {
    #sp_slider_cover .owl-nav div.owl-prev{
        font-size: 3.7vw;
        left: 10px;
    }
    #sp_slider_cover .owl-nav div.owl-next{
        font-size: 3.7vw;
        right: 10px;
    }
    // #slider_section .owl-nav div.owl-next,#slider_section .owl-nav div.owl-prev {
    //     font-size: 6vw;
    //     top: 28%;
    // }
    // #chooseUs_slider_cover .owl-nav div.owl-next {
    //     font-size: 7vw;
    //     right: 10px;
    // }
    // #chooseUs_slider_cover .owl-nav div.owl-prev {
    //     font-size: 7vw;
    //     left: 10px;
    // }
    // #sp_slider_cover .owl-nav div.owl-prev{
    //     font-size: 7vw;
    //     left: 10px;
    // }
    // #sp_slider_cover .owl-nav div.owl-next{
    //     font-size: 7vw;
    //     right: 10px;
    // }
}
// large screen 
@media (min-device-width: 1025px) and (max-device-width: 5120px)  {

}

// data table styles start
.slick-slider {
    width: 100%;
    margin: auto;
  }
  
body .slick-prev, 
body .slick-next {
    height: 45px;
    width: 40px;
    background: grey !important;
    z-index: 99;
}
.slick-next {
    right: -10px;
}
.slick-prev {
    left: -5px;
}
.navbar .nav-item.avatar .dropdown-toggle img {
    height: 35px;
}
.navbar.scrolling-navbar {
    z-index: 100;
}
.fixed-sn main {
    padding-top: 4.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.side-nav .logo-wrapper img {
    height: auto;
    padding: 5% 50px;
}
.slim-nav .side-nav {
    width: 55px;
}
.fixed-sn.slim-nav .double-nav, .fixed-sn.slim-nav footer, .fixed-sn.slim-nav main {
    padding-left: 55px;
}
.slim-nav .toggle-menu-title, .slim-nav .logo-wrapper img {
    display: none;
}
.fixed-sn .side-nav, .fixed-sn .double-nav, .fixed-sn footer, .fixed-sn main {
    transition: width 0.3s;
}
.white-skin .side-nav .collapsible li .collapsible-header.active .fa {
    color: #4285f4;
}
.white-skin .side-nav .collapsible li .collapsible-header.active {
    color: #4285f4;
    background-color: rgba(0, 0, 0, 0.05);
}
.navbar-collapse {
    height: auto !important;
}
.double-nav a {
    color:#495057;
}
.list-group-item {
    color: #495057;
    font-size: 0.875rem;
}
.table .img-fluid {
    max-height: 40px;
}
table.table td, table.table th {
    vertical-align: middle;
    
}
.dnone {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.user-view {
    transition: opacity 0.6s linear;
}
.slick-slide {
    transition: opacity 0.6s linear;
}
.dfade {
    opacity: 0.5;
}
.ajax-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(255 255 255 / 10%);
    z-index: 999;
}
.ajax-loader img {
    position: absolute;
    width: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} 
.show {
    display: block;
}
/*******************************************/
a.tool {
    padding: 0 0.5rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*******************************************/
.table-responsive{
    // border: 1px solid #b9ccd7;
    border-radius: 5px;
}
table.dataTable.no-footer {
    border-bottom: 1px solid #000;
}
table.table thead th {
    border-bottom: 2px solid #51b0e7;
    color: #3f7ac3;
    font-family: Arial;
    text-align: center;
    height: 31px;
    padding: 0;
    font-size: 13px !important;
    // background: #51b0e7;
}
table.table td {
    padding: 0px 15px;
    height: 47px;
    font-size: 12px !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
    outline: none;
    background-color: #51b0e7;
    background: #51b0e7;
    box-shadow: none;
    color: #000;
    border: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: not-allowed;
}
table.dataTable tbody th, table.dataTable tbody td {
    font-family: Arial;
    letter-spacing: 1px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    font-weight: 500;
}


::ng-deep .mat-icon-button{
    background-color:#fff;
    height: 0px !important;
    width: 0px !important;
    border-radius: none;
}

/* mdb-card with tables */
::ng-deep div.dataTables_filter {
    text-align: left !important;
    /* background-image: url(../../../assets/img/search.png); */
    
}
::ng-deep div.dataTables_filter input {
    min-width: 400px !important;
    font-size: 12px;
    font-family: Arial;
    font-weight: 500;
}

::ng-deep .dataTables_info{
    float: right !important;
    font-size: 12px !important;
    font-family: Arial;
    font-weight: 500;
}

::ng-deep .paginate_button {
    font-size: 12px !important;
    font-family: Arial;
    font-weight: 500;
}

::ng-deep .dataTables_length, ::ng-deep .pagination .page-item .page-link, ::ng-deep .dataTables_filter{
    font-size: 0.875rem !important;
}
::ng-deep .dataTables_length label {
    margin:0 !important;
}
::ng-deep .dataTables_length{
    float: right !important;
}
::ng-deep .dataTables_length .custom-select {
    height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    font-size: 0.875rem !important;
    display: inline-block !important;
    margin: 0 !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center no-repeat #fff !important;
    background-size: 8px 10px !important;
}
::ng-deep .dataTables_filter input,.dataTables_filter input:hover, .dataTables_filter input:active {
    padding-top: 0 !important;  
    padding-bottom: 0 !important;
    margin-bottom: 5px  !important;
    border-radius: none !important;
    border: none !important;
    border-bottom: 1px solid #666 !important;
    outline: none !important;
}
::ng-deep .pagination .page-link:focus {
    background-color: #007bff !important;
}

.table-responsive1{
    border: none;
}

@media (min-width: 1440px) {
    
}
@media (min-width: 600px) {
   
}
@media (min-width: 768px) {
    .carousel:not(.slick-slider) {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .carousel:not(.slick-slider) > * {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 1440px) {
    .slimnav-collapse {
        display: none;
    }
}
//  data table styles ends
#casesheet_slider_cover .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#casesheet_slider_cover .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#diet .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#diet .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#mind .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#mind .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#exercise .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#exercise .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#exerciseTracker .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#exerciseTracker .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#CLvital .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#CLvital .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

#medTrack .owl-nav div.owl-prev {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    left: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}
#medTrack .owl-nav div.owl-next {
    font-size: 25px;
    color: #70b1d7;
    position: absolute;
    right: 16px;
    top: 41%;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: transparent;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 0;
    padding: 0 !important;
}


